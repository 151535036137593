@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input:-internal-autofill-selected {
  box-shadow: 0 0 0 30px #fafafa inset;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 30px #fafafa inset;
  -webkit-border-radius: 5px;
  -webkit-padding-start: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:focus-visible {
  outline: #6C757D auto 1px;
}
