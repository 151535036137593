@keyframes openfolder {
  0% {
    transform-origin: 50% 100%;
  }

  55% {
    -webkit-transform: rotateX(-50deg);
    transform: rotateX(-50deg);
  }

  100% {
    transform-origin: 50% 100%;
  }
}

@keyframes liftpaper {
  0% {
    transform: translateY(1px) scale(0.56);
  }

  50% {
    transform: translateY(-32px) translateX(0px) scale(1.1);
  }

  100% {
    transform: translateY(1px) scale(0.56);
  }
}
